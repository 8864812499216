import {FETCH_LOTTO5_CONFIGS} from "./constant";
import axios from "../axios";
import API_USER from "../../endpoints/user";
import {env} from "../../env";

const notSlot = ["range_line", "odd_even"];

const lottoConfig5 = (lottoBetTypes) => {

    const underOver = lottoBetTypes
        .filter((item) => item.type === "under_over" || item.type === "odd_even");
    const ranges = lottoBetTypes
        .filter((item) => item.type === "range");

    return {
        overUnder: underOver,
        ranges: ranges,
    }
}

const lottoConfig6 = (lottoBetTypes) => {
    const rangeLine = lottoBetTypes
        .filter((item) => item.type === "range_line");
    const slots = lottoBetTypes
        .filter((item) => !notSlot.includes(item.type)).map((item) => {
            if (item.code === "UNDER") {
                item.sub = lottoBetTypes.find(e => e.code === "ODD");
            }
            if (item.code === "OVER") {
                item.sub = lottoBetTypes.find(e => e.code === "EVEN");
            }
            return item;
        });

    return {
        rangeLine: rangeLine,
        slots: slots
    }
}
const lottoConfig12 = (lottoBetTypes) => {
    const underOverOddEven = lottoBetTypes
        .filter((item) => item.type === "under_over" || item.type === "odd_even");
    const colors = lottoBetTypes
        .filter((item) => item.type === "color");
    const numbers = lottoBetTypes
        .filter((item) => item.type === "number");

    return {
        underOverOddEven: underOverOddEven,
        colors: colors,
        numbers: numbers,
    }
}

export const fetchLottoConfig = () => (dispatch) => {
    const version = env.VERSION;
    const allConfig = localStorage.getItem("allConfig-v5");
    if (allConfig) {
        const data = JSON.parse(allConfig);
        if (data.version === version) {
            dispatch({
                type: FETCH_LOTTO5_CONFIGS,
                payload: data,
            });
            return Promise.resolve(data);
        }
    }

    return axios
        .get(API_USER.FETCH_BETTYPE_CONFIG, {
            params: {oddEven: env.IS_ODD_EVEN === "TRUE"}
        })
        .then((response) => {
            let data = response.data.data;

            let payload = {
                lotto5Config: lottoConfig5(data.lotto5BetTypes),
                lotto6Config: lottoConfig6(data.lotto6BetTypes),
                lotto12Config: lottoConfig12(data.lotto12BetTypes),
                lotto90Config: data.lotto90BetTypes,
                limit: data.limit,
                packagePrices: data.packagePrices,
                lotto90PackagePrices: data.lotto90PackagePrices,
                lotto99PackagePrices: data.lotto99PackagePrices,
                yukiBetTypes: lottoConfig12(data.yukiBetTypes),
                version: version
            }


            dispatch({
                type: FETCH_LOTTO5_CONFIGS,
                payload: payload,
            });
            localStorage.setItem("allConfig-v5", JSON.stringify(payload));
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
