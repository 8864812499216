import {env} from "../env";
import {createApolloClient} from "../connections/socketServices";


export const clientAp = createApolloClient();

export const getCode = (code) => {
    switch (code) {
        case "ONE":
            return 1;
        case "TWO":
            return 2;
        case "THREE":
            return 3
        case "FOUR":
            return 4
        case "FIVE":
            return 5
        case "SIX":
            return 6
        case "SEVEN":
            return 7
        case "EIGHT":
            return 8
        case "NINE":
            return 9
        case "TEN":
            return 10
        case "ELEVEN":
            return 11
        case "TWELVE":
            return 12
        case "TWO_THREE":
            return "2-3"
        case "THREE_FOUR":
            return "3-4"
        case "FOUR_FIVE":
            return "4-5"
        default:
            return code;
    }
}

export const lottoPaths = ["/lottery/lottery5-mix", "/lottery/lottery6-mix", "/lottery/lottery12-mix", "/lottery",
    "/lottery/win-report", "/lottery/result-lottery-mix", "/lottery/result-lottery5", "/lottery/result-lottery6", "/lottery/result-lottery12",
    "/lottery/lottery5-mix-bet", "/lottery/lottery6-mix-bet", "/lottery/lottery12-mix-bet", "/lottery/lotteries-bet", "/lottery/statement-mix",
    "/lottery-mix"
]

export const getColorNumbers = (code) => {
    switch (code) {
        case "RED":
            return [1, 4, 7, 10];
        case "GREEN":
            return [2, 5, 8, 11];
        case "BLUE":
            return [3, 6, 9, 12];
        default :
            return [];
    }
}


export const fullScreen = (id) => {
    let elem = document.getElementById(id);
    if (elem)
        if (elem.classList.contains("full-screen")) {
            document.exitFullscreen();
            elem.classList.remove("full-screen");
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
            elem.classList.add("full-screen");
        }

}

export const fullScreenLottos = () => {
    let elem = document.getElementById("lotto-mix");
    if (elem)
        if (elem.classList.contains("full-screen")) {

        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
            elem.classList.add("full-screen");
        }

}

export const BetCodes6 = {
    UNDER: "small",
    OVER: "big",
    ONE: "1",
    TWO: "2",
    THREE: "3",
    FOUR: "4",
    FIVE: "5",
    SIX: "6",
}

export const getUpdateWeb = () => {
    const version = localStorage.getItem("user");
    const currentVersion = env.VERSION;
    if (!version || !currentVersion) return null;
    return JSON.parse(version).webVersion !== currentVersion
};

export const post90 = ["A", "B", "C", "D", "E"];
export const post99 = ["A", "B", "C", "D", "E"];

export const BetType = {
    NORMAL: "NORMAL",
    RANGE: "RANGE",
    SPECIALX: "SPECIALX"
}

export const currencyCode = () => {
    let user = localStorage.getItem("user");
    if (!user) {
        return null;
    }
    return JSON.parse(user).currencyCode;

}

export const getZh = {
    100: "一百",
    200: "二百",
    500: "五百",
    1000: "一千",
    2000: "俩千",
    5000: "五千",
    10000: "一万",
    20000: "俩万",
    50000: "五万",
    200000: "二十万",
    500000: "五十万"
}

export const getZhCurrency = {
    KHR: "柬币"
}

export const lott12v2Config = {
    red: [1, 2, 3, 4],
    blue: [9, 10, 11, 12],
    rd: [10, 9, 8, 7],
    green: [5, 6, 7, 8],
    codes: {
        red: ["ONE", "TWO", "THREE", "FOUR"],
        blue: ["NINE", "TEN", "ELEVEN", "TWELVE"],
        green: ["FIVE", "SIX", "SEVEN", "EIGHT"],
    }
}

export const sL12v2Label = {
    "ONE": "01",
    "TWO": "02",
    "THREE": "03",
    "FOUR": "04",
    "FIVE": "05",
    "SIX": "06",
    "SEVEN": "07",
    "EIGHT": "08",
    "NINE": "09",
    "TEN": "10",
    "ELEVEN": "11",
    "TWELVE": "12"
};

export const jackpotNum = {
    "ONE": 1,
    "TWO": 2,
    "THREE": 3,
    "FOUR": 4,
    "FIVE": 5,
    "SIX": 6,
    "SEVEN": 7,
    "EIGHT": 8,
    "NINE": 9,
    "TEN": 10,
    "ELEVEN": 11,
    "TWELVE": 12
}

export const padNumber2 = (num) =>
    String(num).padStart(2, "0");

export const lotto12DrawStyle = {
    circle: "circle",
    square: "square",
}

export const homeLottery = [
    {
        items: [
            {
                value: "5",
                url: "/lottery5",
                image: "lo5",
                title: "5"
            },
            {
                value: "6",
                url: "/lottery6",
                image: "lo6",
                title: "6"
            },
            {
                value: "12",
                url: "/lottery12",
                image: "lo12",
                title: "12"
            },
        ]
    },
    {
        items: [
            {
                value: "90",
                url: "/lottery90",
                image: "lo90",
                title: "90"
            },
            {
                value: "5D",
                url: "/lottery5D",
                image: "lo5d",
                title: "5D"
            },
            {
                value: "yuki",
                url: "/lottery1/12",
                image: "lo12v2",
                title: "1/12"
            },
        ]
    },
    {
        items: [
            {
                value: "99",
                url: "/lottery1/99",
                image: "lotto99",
                title: "1/99"
            },
        ]
    }
]

export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const pfType = env.PLATFORM_FOR === "888" && env.PLATFORM_TYPE !== "P99" ? "888" : env.PLATFORM_TYPE;

export const pfType999 = env.PLATFORM_FOR === "999";

export const pfType999Color = env.PLATFORM_COLOR_999;

export const isOddEven = env.IS_ODD_EVEN === "TRUE";

export const statisticParam = {
    row: 6,
    column: 16,
    skip: 1,
    version: 2
}

export const isOE = (code) => ["ODD", "EVEN"].includes(code);

export const getOECode = (code) => code === "ODD" ? "1,3,5,7,9" : "0,2,4,6,8"

export const getIframePathName = () => {
    try {
        return window?.frameElement?.baseURI;
    } catch (e) {
        return true
    }
}