const env = {
    BASE_URL: window.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL,
    BASIC_TOKEN: window.REACT_APP_BASIC_TOKEN || process.env.REACT_APP_BASIC_TOKEN,
    PLATFORM_FOR: window.REACT_APP_STYLE_FOR || process.env.REACT_APP_STYLE_FOR,
    IS_ODD_EVEN: window.REACT_APP_IS_ODD_EVEN || process.env.REACT_APP_IS_ODD_EVEN,
    PLATFORM_COLOR_999: window.REACT_APP_STYLE_COLOR_999 || process.env.REACT_APP_STYLE_COLOR_999,
    PLATFORM_TYPE: window.REACT_APP_PLATFORM_FOR || process.env.REACT_APP_PLATFORM_FOR,
    SOCKET_URL: window.REACT_APP_SOCKET_URL || process.env.REACT_APP_SOCKET_URL,
    SOCKET_IO_URL: window.REACT_APP_SOCKET_IO_URL || process.env.REACT_APP_SOCKET_IO_URL,
    FEATURES: window.REACT_APP_FEATURES || process.env.REACT_APP_FEATURES,
    PLATFORM_ALLOW: window.REACT_APP_PLATFORM_ALLOW || process.env.REACT_APP_PLATFORM_ALLOW,
    VERSION: "1.0.38"
};

export {env};
