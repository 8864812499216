import {getUser} from "../../utils/cached";
import React from "react";
import {withTranslation} from "react-i18next";
import {env} from "../../env";


const ShowUpdateVersion = ({t}) => {
    let version = env.VERSION;
    let user = getUser();
    if (version !== user?.webVersion) {
        return <div
            className="bg-red w-100 text-white text-center py-2">{t("messages:system_updated")}</div>
    }
    return <></>;
}

export default withTranslation()(ShowUpdateVersion);
